.side-menu {
    height: 100vh;
    width: 15%;
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;

}

.side-menu.active {
    left: 0;
    transition: 550ms;
}

.content {
    background-color: rgba(19, 34, 50, 1);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-inline: 5px;
    padding-block: 15px;
    gap: 30px;
    width: 100%;
    height: 100%;
}

.transparent {
    display: none;
}

@media only screen and (max-width: 800px) {
    .icons-stroke {
        width: 25px;
    }

    .user-icon {
        width: 50px;
    }

    .content {
        overflow-y: scroll;
        width: 70%;
    }

    .side-menu {
        position: fixed;
        width: 100%;
        top: 0;
        left: -100%;
        transition: 860ms;
    }

    .transparent {
        height: 100%;
        width: 30%;
        display: unset;
    }

}
