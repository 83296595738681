.icons-stroke {
    width: 12%;
    stroke: rgb(86, 107, 130);
    cursor: pointer;
}

.questionMark{
    width: 3%;
}

.alert {
    width: 6%;
    stroke: rgb(39, 49, 58);
}

.logout:hover {
    stroke: white;
}

.hamburger-menu {
    stroke: rgba(19, 34, 50, 1);
}

.user-icon {
    width: 25%;
}

.expand-icon {
    width: '100%';
    stroke: rgb(86, 107, 130);
    cursor: pointer;
}

.copy-icon {
    width: 20px;
    fill: rgb(86, 107, 130);
}

.action-icons {
    width: 20px;
    cursor: pointer;
    stroke: rgb(86, 107, 130);
}
.action-icons:hover {
    stroke: rgb(19, 71, 175);
    scale: 1.1;
}

@media (max-width: 800px) {
    .alert {
        width: 100px;
    }

}