.navbar {
    height: 5vh;
    background-color: white;
    box-sizing: border-box;
    border: 2px solid rgb(213, 225, 239);
    position: sticky;
    z-index: 0;
    top: 0; 
    padding: 0.5vh;
    display: flex;
    align-items: center;
}