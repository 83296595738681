@media only screen and (max-width: 799px) {
    html {
        font-size: 14px;
    }
}

@media only screen and (min-width: 800px) {
    html {
        font-size: 10px;
    }

    .hamburger-menu {
        display: none;
    }
}

@media only screen and (min-width: 1020px) {
    html {
        font-size: 12px;
    }
}

@media only screen and (min-width: 1200px) {
    html {
        font-size: 14px;
    }
}

@media only screen and (min-width: 1370px) {
    html {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1921px) {
    html {
        font-size: 24px;
    }
}


