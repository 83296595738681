.card {
    border-radius: 15px;
    padding-block: 10px;
    padding-inline: 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    position: relative;
}